import { CatalogosSimpleResult } from './models/CatalogosSimpleResult.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpProvider } from './providers/http/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Product } from './models/product.model';
import { Category } from './models/category.model';
import { ArticulosSimpleResult } from './models/ArticulosSimpleResult.model';
import { Carro } from './models/carro.model';
import { pendiente } from './models/pendiente.model';
import { Carros } from './models/carros.model';
import { Storage } from '@ionic/storage';
import { __values } from 'tslib';
import { GlobalService } from './services/global.service';
import { Clientes } from './models/clientes.model';
import { Cliente } from './models/cliente.model';
import { mensaje } from './models/mensaje.model';
import { ChatModel } from './models/chat.model';
import { pendientessimple } from './models/pendientesimple.model';
import { filtrossimple } from './models/filtrosimple.model';
import { HTTP } from '@ionic-native/http/ngx';
import { CategoriaSimpleResult } from './models/CategoriasSimpleResult.model';
import { filtros } from './models/filtros.model';
import { familia } from './models/familia.model';
import { Router } from '@angular/router';
import { parametros } from './models/parametros.model';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  promocion: string;
  // public Host: String = 'https://overlaysistemas.com/overapi2';
  // tslint:disable-next-line:ban-types
   //public Host: String = 'https://distribucionesgoyo.es/Admin_sinc2/overapi2';
  public Host: String = "-";
  //HOLA SEMA public Host: String = 'https://esparcia.es/Admin_sinc2/overapi2';
  // public Host: String = 'https://covadul.site/overapi

  //public Host: String = 

  
  constructor(private http: HttpProvider, private httpClient: HttpClient,
              private router: Router, private  storage: Storage, public global: GlobalService, private httpNative: HTTP) {
    this.Host = this.global.host;
  }

  // Hecho desde para borrar
  
  damePromocion(producto) {
    this.promocion = '';
    if (producto.promocion.descuentoP != 0) {
      this.promocion = this.promocion + 'Promoción disponible, descuento de ' + producto.promocion.descuentoP + '% sobre el precio \n';
    }
    if (producto.promocion.descuentoI != 0.00) {
      this.promocion = this.promocion + 'Promoción disponible, descuento de ' + producto.promocion.descuentoI + '€ sobre el precio \n';
    }
    if (producto.promocion.regalo != null) {
      this.promocion = this.promocion + 'Promoción disponible, regalo de producto \n';
    }
    if (producto.promocion.desde != 0) {
      this.promocion = this.promocion + 'para compras superiores a ' + producto.promocion.desde;

      if (producto.precioSobre === 'N') {
      if (producto.medi1 !== '' && producto.medi1 !== 'unid1') {
        this.promocion = this.promocion + producto.medi1;
        } else {
          this.promocion = this.promocion + ' unidades';
        }
      } else {
        if (producto.medi2 !== '' && producto.medi2 !== 'unid2') {
          this.promocion = this.promocion + producto.medi2;
          } else {
            this.promocion = this.promocion + ' cajas';
          }
      }
      }
    return this.promocion;
    }

  dametexto(item) 
  {
      if ( ( this.global.unidadMedidaMiniaturas329 == 0 ) || (this.global.unidadMedidaMiniaturas329 == 2 && item.precioSobre == 'N')) 
      {
        
        if ((item.medi2 !== '') && (item.medi2 !== 'unid2')) 
        {
          return item.medi2;
        } 
        else 
        {
          return 'Unidades';
        }
      } 
      else 
      {
        
        if ((item.medi1 !== '') && (item.medi1 !== 'unid1')) 
        {
          return item.medi1;
        } 
        else 
        {
          return 'Cajas';
        }
      }
  }


  async getArticulos(ruta): Promise<Product[]> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    // tslint:disable-next-line:no-shadowed-variable
    const promise = new Promise<Product[]>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: ArticulosSimpleResult) => {
          resolve(resp.Articulos);
        },
          err => {
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getCategoriasproducts(ruta): Promise<Product[]> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    // tslint:disable-next-line:no-shadowed-variable
    const promise = new Promise<Product[]>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: ArticulosSimpleResult) => {
          resolve(resp.Articulos);
        },
          err => {
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getCategorias(ruta): Promise<Category[]> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};
    console.log(url);
    // tslint:disable-next-line:no-shadowed-variable
    const promise = new Promise<Category[]>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: CategoriaSimpleResult) => {
          console.log(resp.Categorias);
          resolve(resp.Categorias);
        },
          err => {
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getCarrito(ruta): Promise<Carro> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    const promise = new Promise<Carro>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: Carro) => {
          console.log('Test 1: ' + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          resolve(resp);
        },
          err => {
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getCarritos(ruta): Promise<Carro[]> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    const promise = new Promise<Carro[]>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: Carros) => {
          console.log('Test 1: ' + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          resolve(resp.Carritos);
        },
          err => {
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getPendiente(ruta): Promise<pendiente[]> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    
    const promise = new Promise<pendiente[]>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: pendientessimple) => {
          console.log('Test 1: ' + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          resolve(resp.pendientes);
        },
          err => {
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getFiltros(ruta): Promise<filtros> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    const promise = new Promise<filtros>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: filtros) => {
          resolve(resp);
          console.log(JSON.stringify(resp));
        },
          err => {
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getParametros(ruta): Promise<parametros> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    const promise = new Promise<parametros>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: parametros) => {
          resolve(resp);
          // console.log(JSON.stringify(resp));
          console.log(resp);
          // busco el 513, 074 y 329
        },
          err => {
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async getCliente(ruta): Promise<Cliente[]> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + ruta;
    const postParams = {};

    // tslint:disable-next-line:no-shadowed-variable
    const promise = new Promise<Cliente[]>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: Clientes) => {
          console.log('Test 1: ' + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          resolve(resp.clientes);
        },
          err => {
            console.log(err);
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async chatMensaje(mensaje: string, tipoAdjunto: string = '', adjunto: any = '', extension: string = ''): Promise<mensaje> {
    if (!mensaje) {
      mensaje = '';
    }
    const formData = new FormData();
    if (tipoAdjunto !== '') {
        console.log('Tipo adjunto ' + tipoAdjunto + ' \\ ' + adjunto);
        formData.append(tipoAdjunto, adjunto);
      }
    if (adjunto !== '') {
        console.log('Adjunto ' + adjunto);
        formData.append('file', adjunto);
      }
    if (extension !== '') {
        console.log('Extension ' + extension);
        formData.append('extension', extension);
      }

    console.log( this.global.usuarioId,mensaje);

    return this.post<mensaje>(this.Host + '/api/chatn/mensaje/' + this.global.usuarioId + '?mensaje=' + mensaje, formData);

  }

  // Metodo auxiliar para hacer los putos POST segun sea nativo o web
  private post<T>(url: string, formData: FormData): Promise<T> {
    console.log(formData);
    if (this.global.esWeb === false) {
      const options = {Authorization: 'Bearer ' + this.global.token};
      this.httpNative.setDataSerializer('multipart');
      const promise = new Promise<T>(async (resolve, reject) => {
        await this.httpNative.post(url, formData, options)
          .then(data => {
            console.log('Respuesta : ' + data.data);
            const d = JSON.parse(data.data);
            resolve(d);
          })
          .catch(error => {
            console.log('Falla, error: ' + error); // error message as string
            resolve(error);
            return error;
          });
      });
      return promise;
    } else {
      const tokenValue = 'Bearer ' + this.global.token;
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization : tokenValue,
          enctype: 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA' // boundary=----WebKitFormBoundaryuL67FWkv1CA
        })
      };
      const promise = new Promise<T>((resolve, reject) => {
        this.httpClient.post(url, formData, httpOptions).toPromise()
        .then((resp: T) => {
          resolve(resp);
        },
          err => {
            console.log('Error status' + err.status + ' : ' + err);
            resolve(err);
            return err;
          }
        );
      });
      return promise;
    }
  }

  async MensajesNoLeidos(): Promise<number> {
    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + '/api/chatn/numsinleer/' + this.global.grupo;
    const postParams = {};
    console.log(url);
    const promise = new Promise<number>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: number) => {
          console.log(resp);
          this.global.mensajesSinLeer = resp;
          resolve(resp);
        },
          err => {
            console.log(err);
            console.log(err.status);
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  // async chatMensajes(idUltimoMensajeObtenido, hastaid): Promise<mensaje[]> {
    async chatMensajes(url): Promise<mensaje[]> {
    // console.log('Ultimo mensaje obtenido: ' + idUltimoMensajeObtenido);
    const options = {Authorization: 'Bearer ' + this.global.token};

    /*const url = this.Host + '/api/chatn/mensajesanteriores/'+this.global.usuarioId+'?idUltimoMensajeObtenido=' + idUltimoMensajeObtenido;
    if(hastaid === 0){
      console.log('Hasta id: '+hastaid);
      // tslint:disable-next-line:max-line-length
      const url = this.Host + '/api/chatn/mensajesanteriores/'+this.global.usuarioId+'?idUltimoMensajeObtenido=' + idUltimoMensajeObtenido + '&hastaid=' + hastaid;
    }*/
    const postParams = {};
    console.log('URL: ' + url);
    const promise = new Promise<mensaje[]>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((datos: ChatModel) => {
          console.log(url, datos);
          resolve(datos.mensajes);
        },
          err => {
            console.log(err);
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async marcarLeidos(url) {
    const options = {Authorization: 'Bearer ' + this.global.token};
    // const url = this.Host + '/api/chatn/marcarLeido/' + this.global.grupo;
    const postParams = {};
    console.log('URL marcarLeidos: ' + url);

    const tokenValue = 'Bearer ' + this.global.token;
    const httpOptions = {
        headers: new HttpHeaders({
            Authorization : tokenValue,
            enctype: 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA'
          })
        };
    const promise = new Promise<any>((resolve, reject) => {
          this.http.post(url, {}, httpOptions).toPromise()
          .then((resp: any) => {
            resolve(resp);
          },
            err => {
              console.log('Error status: ' + err.status + ' : ' + err.message);
              resolve(err);
            }
          );
        });
    return promise;
  }

  async recuperaPass(email): Promise<string> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + '/api/cliente/recuperarclave?email=' + email;
    const postParams = {};

    const promise = new Promise<string>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then(() => {
          // console.log("Test 2: " + res.Articulos[0].images);
          resolve('Recuperada');
        },
          err => {
            console.log(err);
            reject(err);
            return err;
          }
        );
    });
    // return promise;
    return 'Recuperada';
  }

  async cartClose(): Promise<any> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + '/api/cart/finalizar';
    const postParams = {};

    return await new Promise<any>((resolve, reject) => {
      this.http.get(url, postParams, options).toPromise()
        .then((resp: string) => {
          console.log('Resp: ' + resp);
          resolve('202');
        },
          err => {
            console.log(err);
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            resolve(err.status);
          }
        );
    });
    // return promise;
  }

  async cartAdd(articulo): Promise<any> {

    console.log('El articulo recibido: ' + articulo.codigo);

    const options = {Authorization: 'Bearer ' + this.global.token};
    // tslint:disable-next-line:max-line-length
    const url = this.Host + '/api/cart/articulo/' + articulo.codigo.replace(/ /g, '') + '?unid1=' + articulo.unid1 + '&unid2=' + articulo.unid2;
    const postParams = {};

    return await new Promise<string>((resolve, reject) => {
      this.http.put(url, postParams, options).toPromise()
        .then((resp: string) => {
          console.log('Carro : ' + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          // this.global.artcart = this.global.artcart + 1;
          articulo.cesta = true;          
          resolve(resp);
        },
          err => {
            articulo.cesta = false;
            // console.log(err);
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
          }
        );
    });
    // return promise;
  }



  async dameUnidades(articulo) 
  {

    console.log('Unidad 1: ' + articulo.unid1);
    console.log('Unidad 2: ' + articulo.unid2);

    if (this.global.unidadMedidaMiniaturas329 == 0) 
    {
        if (articulo.unid2 == 0) 
        {
          articulo.unid2 = 1;
        }

        if (articulo.formaRedondeo == 4 ) 
        {
          articulo.unid1 = Math.ceil(articulo.unid2 / articulo.relacionUnidades);
        } 
        else if (articulo.formaRedondeo == 6 ) 
            {
                articulo.unid1 = Math.round((articulo.unid2 / articulo.relacionUnidades) * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
            } 
            else if (articulo.formaRedondeo == 8 ) 
                {
                    articulo.unid1 = Math.round(articulo.unid2 / articulo.relacionUnidades);
                } 
                else if ( articulo.formaRedondeo == 1 || articulo.formaRedondeo == 2) 
                    {
                        articulo.unid1 = articulo.unid2 / articulo.relacionUnidades;
                    } 
                    else if ( articulo.formaRedondeo == 5) 
                        {
                          articulo.unid1 = 0;
                        }
    } 
    else  if (this.global.unidadMedidaMiniaturas329 == 1) 
          {
            if (articulo.unid1 == 0) 
            {
              articulo.unid1 = 1;
            }

            if (articulo.formaRedondeo == 4 ) 
            {
              articulo.unid2 = Math.ceil(articulo.unid1 * articulo.relacionUnidades);
            } 
            else if (articulo.formaRedondeo == 6 ) 
                  {
                    articulo.unid2 = Math.round((articulo.unid1 * articulo.relacionUnidades) * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
                  } 
                  else if (articulo.formaRedondeo == 8 ) 
                      {
                        articulo.unid2 = Math.round(articulo.unid1 * articulo.relacionUnidades);
                      }   
                      else if ( articulo.formaRedondeo == 1 || articulo.formaRedondeo == 2) 
                          {
                            articulo.unid2 = articulo.unid1 * articulo.relacionUnidades;
                          } 
                          else if ( articulo.formaRedondeo == 5) 
                              {
                                articulo.unid2 = articulo.unid1 * articulo.relacionUnidades;
                                articulo.unid1 = 0;
                              }

    } else if (this.global.unidadMedidaMiniaturas329 == 2) {
      if (articulo.precioSobre === 'S') {
        if (articulo.unid1 === 0) {
          articulo.unid1 = 1;
          }

        if (articulo.formaRedondeo == 4 ) {
            articulo.unid2 = Math.ceil(articulo.unid1 * articulo.relacionUnidades);
          } else if (articulo.formaRedondeo == 6 ) {
            articulo.unid2 = Math.round((articulo.unid1 * articulo.relacionUnidades) * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
          } else if (articulo.formaRedondeo == 8 ) {
            articulo.unid2 = Math.round(articulo.unid1 * articulo.relacionUnidades);
          } else if ( articulo.formaRedondeo == 1 || articulo.formaRedondeo == 2) {
            articulo.unid2 = articulo.unid1 * articulo.relacionUnidades;
          } else if ( articulo.formaRedondeo == 5) {
            articulo.unid2 = articulo.unid1 * articulo.relacionUnidades;
            articulo.unid1 = 0;
          }

        } else {
          if (articulo.unid2 == 0) {
          articulo.unid2 = 1;
          }

          if (articulo.formaRedondeo == 4 ) {
            articulo.unid1 = Math.ceil(articulo.unid2 / articulo.relacionUnidades);
          } else if (articulo.formaRedondeo == 6 ) {
            articulo.unid1 = Math.round((articulo.unid2 / articulo.relacionUnidades) * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
          } else if (articulo.formaRedondeo == 8 ) {
            articulo.unid1 = Math.round(articulo.unid2 / articulo.relacionUnidades);
          } else if ( articulo.formaRedondeo == 1 || articulo.formaRedondeo == 2) {
            articulo.unid1 = articulo.unid2 / articulo.relacionUnidades;
          } else if ( articulo.formaRedondeo == 5) {
            articulo.unid1 = 0;
          }

        }
  }
    console.log(articulo);
    return articulo;

  }





  async cartDel(articulo): Promise<string> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + '/api/cart/articulo/' + articulo;
    const postParams = {};
    console.log(articulo);

    // console.log(url);

    const promise = new Promise<string>((resolve, reject) => {
      this.http.del(url, postParams, options).toPromise()
        .then((resp: string) => {
          // console.log("Test 1: " + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          this.global.artcart = this.global.artcart - 1;
          resolve(resp);
        },
          err => {
            console.log(err);
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async FavAdd(articulo): Promise<string> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + '/api/wishlist/articulo/' + articulo;
    const postParams = {};

    const promise = new Promise<string>((resolve, reject) => {
      this.http.put(url, postParams, options).toPromise()
        .then((resp: string) => {
          // console.log("Test 1: " + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          resolve(resp);
        },
          err => {
            console.log(err);
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

  async FavDel(articulo): Promise<string> {

    const options = {Authorization: 'Bearer ' + this.global.token};
    const url = this.Host + '/api/wishlist/articulo/' + articulo;
    const postParams = {};

    // console.log(url);

    const promise = new Promise<string>((resolve, reject) => {
      this.http.del(url, postParams, options).toPromise()
        .then((resp: string) => {
          console.log("Test 1: " + resp);
          // console.log("Test 2: " + res.Articulos[0].images);
          resolve(resp);
        },
          err => {
            console.log(err);
            console.log(err.status);
            if (err.status === 401) {
              this.global.cargando = false;
              this.router.navigateByUrl('/signin');
              }
            reject(err);
            return err;
          }
        );
    });
    return promise;
  }

}
