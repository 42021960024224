import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { Carro } from '../models/carro.model';
import { Platform } from '@ionic/angular';
import { parametros } from '../models/parametros.model';
import { Category } from '../models/category.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private platform: Platform) {
    //this.host = 'https://covadul.site/overapi';
    //this.host = 'https://overlaysistemas.com/overapi2';
    //this.host = 'https://universosnack.com/overapi';
    //this.host = 'https://esparcia.es/Admin_sinc2/overapi2';
    //this.host = 'https://manjaral.com/overapi';
    //this.host = 'http://192.168.0.224:8888/slim4'; // Vioque local tests
    //this.host = 'https://monforte.shop/Admin_sinc2/overapi2';
    //this.host = 'http://192.168.0.236:8000/Admin_sinc2/overapi2';
    //this.host = 'https://distribucionesgoyo.es/Admin_sinc2/overapi2';
    //this.host =  'https://salom.overlink.es/Admin_sinc2/overapi2';
    this.host = 'https://exclusivaspeco.overlink.es/Admin_sinc2/overapi2';
    //this.host = 'https://casalma.overlink.es/Admin_sinc2/overapi2';
    //this.host = 'https://overlay.overlink.es/Admin_sinc2/overapi2';
    //this.host =  'https://disantos.overlink.es/Admin_sinc2/overapi2';
    //this.host =  'https://esparcia.overlink.es/Admin_sinc2/overapi2';
    this.esWeb = platform.is('desktop') || platform.is('mobileweb');
   }

    // Host
    public host: string;

    // filtro categorias
    public filtroCat: string = '';

    // Titulo
    //public titulo: string = 'Emicela';
    //public titulo: string = 'Overlay';
    //public titulo: string = 'Casalma';
    public titulo: string = 'Exclusivas Peco';
    //public titulo: string = 'Manjaral';
    //public titulo: string = 'Monforte';
    //public titulo: string = 'Covadul';
    //public titulo: string = 'Overlay Software';
    //public titulo: string = 'Distribuciones Alimenticias Salom';
    //public titulo: string = 'Esparcia Café';
    //public titulo: string = 'Distribuciones Goyo';
    //public titulo: string = 'Distribuciones Santos S.L.';

  //direccion cliente
  //public url = 'https://overlaysistemas.com/';
  //public url = 'https://universosnack.com/';
  //public url = 'https://manjaral.com/';
  //public url = 'https://esparcia.es/';
  //public url = 'https://covadul.site/';
  //public url = 'https://monforte.shop/';
  //public url = 'https://overlay.overlink.es/';
 //public url = 'https://distribucionesgoyo.es/';
  //public url = 'https://exclusivaspeco.com/';
  //public url = 'https://casalma.overlink.es/';
  //public url = 'https://salom.overlink.es/';
  public url = '';

    // List of product
    public products: Product[];

    // List of product
    public productsPref: Product[];

    // List of categories
    public categorias: Category[];

  // Lista de parametros
  public parametros: parametros;

  // Parametro 074
  public decimalesUnidades074: number;

  // Parametro 329
  public unidadMedidaMiniaturas329: number;

   // Filtro
   public opciones: any = null;

  // productos del carro
  public cartProducts: Carro;

  // categoria de productos activa
  public catProducts: string;

  // nombre categoria de productos activa
  public catName: string;

  // Lista de productos del historico
  public historico: Product[];

  // Lista de productos deseados
  public whislist: Product[];

  //control de cambio
  public cambio = false;

  //control de carga
  public cargando = false;

  //control de cambio
  public token = '';

  //identificador de máquina
  public deviceID = '';

  public esWeb : boolean;

  //articulos en el carro
  public artcart = 0;

  //articulos en el carro
  public mensajesSinLeer = 0;

  //Articulo al chat
  public itemChat;

  public usuarioId = '';

  public grupo = '';

  public usuario = '';

  public password = '';

}
